export default (theme) => ({
  cell: {
    padding: '10px',
  },
  customGrid: {
    padding: '5px',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
});
