export default (theme) => ({
  entityOption: {
    border: '1px solid #e8463a',
    borderRadius: 10,
    marginRight: 20,
    paddingRight: 15,
    marginBottom: 15,
    marginLeft: 0,
  },
});
