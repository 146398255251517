export default (theme) => ({
  title: {
    fontWeight: 100
  },
  roleContainer: {
    backgroundColor: '#bcdff1',
    borderRadius: 4,
    padding: 4,
    fontSize: 12
  },
});
